<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">水质</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    label="设备"
                    prop="JCDID"
                    v-if="formdata.dataDic.SFFSBH != 0"
                >
                    <!-- <el-select
                        v-model="formdata.dataDic.JCDID"
                        placeholder="请选择设备"
                        :disabled="true"
                    >
                        <el-option
                            v-for="(item, index) in SbList"
                            :key="`${item.ID}-${index}`"
                            :label="item.SBMC"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select> -->
                    <el-input
                        v-model="formdata.dataDic.SBMC"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="时间" prop="JCSJ">
                    <el-date-picker
                        v-model="formdata.dataDic.JCSJ"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime"
                        :disabled="!isEdit"
                        placeholder="请选择时间"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="ph值" prop="PH">
                    <el-input
                        v-model="formdata.dataDic.PH"
                        placeholder="请输入ph值"
                        @input="toDecimal($event, 'PH')"
                        :disabled="!isEdit"
                    >
                        <template slot="append"></template>
                    </el-input>
                </el-form-item>
                <el-form-item label="溶解氧" prop="RJY">
                    <el-input
                        v-model="formdata.dataDic.RJY"
                        placeholder="请输入溶解氧"
                        @input="toDecimal($event, 'RJY')"
                        :disabled="!isEdit"
                    >
                        <template slot="append">mg/L</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="温度" prop="WD">
                    <el-input
                        v-model="formdata.dataDic.WD"
                        placeholder="请输入温度"
                        @input="toDecimal($event, 'WD')"
                        :disabled="!isEdit"
                    >
                        <template slot="append">℃</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="浊度" prop="ZD">
                    <el-input
                        v-model="formdata.dataDic.ZD"
                        placeholder="请输入浊度"
                        @input="toDecimal($event, 'ZD')"
                        :disabled="!isEdit"
                    >
                        <template slot="append">NTU</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="COD" prop="COD">
                    <el-input
                        v-model="formdata.dataDic.COD"
                        placeholder="请输入深度"
                        @input="toDecimal($event, 'COD')"
                        :disabled="!isEdit"
                    >
                        <template slot="append">m</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="氧化还原电位" prop="YHHYDW">
                    <el-input
                        v-model="formdata.dataDic.YHHYDW"
                        placeholder="请输入氧化还原电位"
                        :disabled="!isEdit"
                    >
                        <template slot="append"></template>
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../../0_com_js/info_mixin.js";
export default {
    name: "zr_sz",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "8020510",
                dataDic: {
                    JCDID: "",
                    JCSJ: "",
                    PH: "",
                    RJY: "",
                    WD: "",
                    ZD: "",
                    COD: "",
                    YHHYDW: "",
                },
            },
            SGLXList: [],
            SbList: [],
        };
    },
    mounted() {
        this.getSbList();
    },
    methods: {
        ...mapActions(["GetGLXList"]),
        async getSbList() {
            let res = await this.GetGLXList({
                itemcode: "sbqd",
                lm: "1",
            });
            this.SbList = res.ResultValue || [];
        },
    },
};
</script>

<style>
.yearBox .yearItem {
    width: 48% !important;
}
.yearBox .separate {
    padding: 0 10px;
}
</style>
